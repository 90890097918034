<template>
    <div class="login-page">
        <div class="page-left">
            <img class="img-icon" src="@/assets/new-logo.jpg" @click="skipMysitefy"/>
        </div>
        <div class="page-right">
            <div @click="toggleDownload" class="down-load-btn">
                <img
                    src="@/assets/downloadBlack.png"
                    alt="download"
                    class="down-load-img"
                />
                <span class="down-text">下载领聚云APP，享更多掌上便捷</span>
                <div v-show="downloadShow" class="code-show">
                    <img src="@/assets/code.png" alt="code" />
                </div>
            </div>
            <div class="login-content-box">
                <div class="top-title">
                    Mysitefy用户平台登录
                </div>
                <div class="login-content">
                    <!-- 登录 -->
                        <div class="setCodeBox">
                            <div>
                                <div v-if="loginStyle === 'ewm'" class="ewmPic">
                                    <div class="ewmImg">
                                        <img :src="`data:image/png;base64,${qrCodeData.qr_code}`" alt="">
                                        <div v-if="checkQrCdoeStatusData.code === 20003" class="expiredImg" @click="getQrCode()">
                                            <div class="text">
                                                二维码已过期
                                            </div>
                                            <div class="refresh">
                                                <img src="@/assets/refresh-btn.png" alt="" />
                                                <p>刷新</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="qrCodeTips" class="qr-code-tips"
                                        :style="{
                                        color:
                                        checkQrCdoeStatusData.code === 20002 ? '#4A82F4': checkQrCdoeStatusData.code === 20004 ||
                                        checkQrCdoeStatusData.code === 20005 || colorCode === 20004 ||
                                        colorCode === 20005 ? '#EE2B2B' : ''
                                        }"
                                        v-html="qrCodeTips">
                                    </div>
                                    <div v-else class="ewmText">
                                        <p>请使用</p>
                                        <p class="ljApp">“领聚云APP”</p>
                                        <p>扫描上方二维码登录</p>
                                    </div>
                                </div>
                                <el-form v-else ref="form" @submit.native.prevent :model="form" :rules="rules">
                                    <div class="setPassText">
                                        <p @click="checkLoginStyle('name')" :class="{ 'checkName': loginStyle==='name' }">账号密码登录</p>
                                        <p @click="checkLoginStyle('code')" :class="{ 'checkName': loginStyle==='code' }">短信验证登录</p>
                                    </div>
                                    <el-form-item>
                                        <div class="pass-inp">
                                           <div class="userInfo">
                                               <img :src="accountInfo.avatar" alt="头像">
                                               <p class="phone">{{accountInfo.mobile}}</p>
                                               <p class="checkPhone" @click="checkMobile">更换账号</p>
                                           </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-if="loginStyle==='name'" prop="password">
                                        <div class="pass-inp">
                                            <p>密码</p>
                                            <div class="pass-box">
                                                <el-input :type="passwordType?'text':'password'" ref="code" placeholder="请输入密码" v-model="form.password" />       
                                                <template v-if="form.password.length">
                                                    <img v-show="!passwordType" src="../../assets/passWord.png" alt="" @click="passwordLook">
                                                    <img v-show="passwordType" src="../../assets/hidden-img.png" alt="" @click="passwordLook">
                                                </template>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-if="loginStyle==='code'" prop="code">
                                        <div class="pass-inp getCode">
                                            <el-input ref="password" placeholder="请输入验证码" v-model="form.code"></el-input>
                                            <div class="get-code" :class="{'codeDisable': timer!==null}" @click="getCode">{{codeText}}</div>
                                        </div>
                                    </el-form-item>
                                    <div v-if="loginStyle==='name'" class="handle-text">
                                        <el-checkbox v-model="checked">自动登录</el-checkbox>
                                        <p @click="forgetPassPw">忘记密码?</p>
                                    </div>
                                    <el-form-item>
                                        <div class="loginBtn">
                                            <el-button class="confirmBtn" :loading="loadingBtn" @click="loginStyle==='name'?confirmNext():noteLogin()">登录</el-button>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                </div>
                <div class="login-go">
                    没有账号？<a :href="loginUrl" class="no-under">现在注册</a>
                </div>
                <div class="maskGroup" @click="ewm">
                    <img :src="require('../../assets/img/'+rightFootUrl)" alt="二维码">
                </div>
            </div>

            <div class="copyright">
                <div class="copy">
                    Copyright © 2016-2022 Mysitefy All rights reserved
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ajax from "../../js/ajax.js";
import global from "../../../global.js";
import { setStorage } from "@/utils/main.js";
export default {
    components: {},
    data() {
        return {
            form:{
               password: '',
               code: ''
            },
            qrCodeTips: "",
            downloadShow: false, // 是否显示下载二维码
            rules:{
                password: [
                    // { required: true, message: "请输入密码", trigger: "blur" },
                    // { min: 8, message: "密码长度为8-20位之间(必须包含数字和字母)", trigger: "blur" },
                    { required: true, message: "请输入密码", trigger: "blur" }
                ],
                code:[
                    { required: true, message: "请输入验证码", trigger: "blur" },
                    { min: 6, max:6, message: "请输入6位数的验证码", trigger: "blur" },
                    { pattern: /^[1-9]+[0-9]*$/, message: "请输入纯数字的验证码", trigger: "blur" }
                ]
            },
            loginStyle: 'code',
            rightFootUrl: "Mask Group.png",
            checked: true,
            codeText: '获取验证码',
            Countdown: 60, // 默认倒计时60s
            timer: null, // 全局定时器
            ewmTimer: null,
            qrCodeData: {},
            checkQrCdoeStatusData: {},
            colorCode: '',
            loadingBtn: false,
            passwordType: false,
            accountInfo: {
                avatar: '',
                is_set_password: '',
                mobile: ''
            },
        };
    },
    computed: {
        loginUrl() {
             switch (global.env) {
                case "dev": // dev
                    return "http://10.10.20.228:3030/register";
                case "test": // test
                    return "http://testregister.linkjoint.cn/register";
                case "pro": // pro
                    return "https://accounts.mysitefy.com/register";
                default:
                    return "http://192.168.2.101:8080/register"; // 本机环境默认开发
            }
        },
		url() {
			if (global.env === "dev") {
				return "http://10.10.20.228:6060";
			} else if (global.env === "test") {
				return "http://testnodeclient.linkjoint.cn";
			} else if (global.env === "local") {
				return "http://192.168.2.101:3000";
			} else {
				return "https://platform.mysitefy.com";
			}
		}
    },
    async created() {
        this.accountInfo = JSON.parse(sessionStorage.getItem('accountInfo'));
        this.loginStyle = this.accountInfo.is_set_password===1 ? "name" : "code"
    },

    mounted() {
    },
    methods: {
		async refreshUser() {
			const { data: user } = await ajax.get("/api/user/info");
			return JSON.stringify(user.data);
		},
        skipMysitefy() {
            window.location.href='https://www.mysitefy.com/'
        },
        toggleDownload() {
            this.downloadShow = !this.downloadShow;
        },
        // 二维码
        ewm() {
            if (this.rightFootUrl === 'Mask Group.png') {
                this.getQrCode()
            }else{
                if (this.ewmTimer) {
                   clearInterval(this.ewmTimer);
                }
            }
            this.loginStyle = this.rightFootUrl === 'Group.png'? 'name':'ewm'
            this.rightFootUrl = this.rightFootUrl === 'Mask Group.png'?'Group.png':'Mask Group.png'
        },
        // 密码查看
        passwordLook(){
            this.passwordType = !this.passwordType
        },
        confirmNext() {
            this.$refs.form.validateField('password',emailError => {
                if (!emailError) {
                   this.nextStep()
                } else {
                    return false;
                }
            });
        },
        // 短信登录
        noteLogin() {
            this.$refs.form.validateField('code',emailError => {
                if (!emailError) {
                   this.noteStep()
                } else {
                    return false;
                }
            });
        },
        // 验证
        async nextStep() {
            const is_remember_login_status = this.checked?1:0
            const password = this.$md5(this.form.password)
            this.loadingBtn = true
            const { data } = await ajax.post("/api/sso/account/login", { 
                username: this.accountInfo.mobile,
                password,
                is_silent_login: 0,
                is_remember_login_status
                });
            this.loadingBtn = false
            if (data.code === 200) {
                this.$message.success('登录成功')
                setStorage('token', data.data)
				this.refreshUser().then((res) => {
					if(JSON.parse(res).tid){
						window.open(
						`${this.url}/welcome?token=${data.data}&user=${res}`,
						"_self"
						);
					}else{
						this.$router.push({ path: "/chooseTeam" });
					}
				});
            }           
        },
        // 短信
        async noteStep() {
            const { mobile } = this.accountInfo
            const { code } =  this.form
            this.loadingBtn = true
            const { data } = await ajax.post("/api/sso/mobile/login", {
                mobile,
                code
            });
            this.loadingBtn = false
            if (data.code === 200) {
                setStorage('token', data.data);
				this.refreshUser().then((res) => {
					if(JSON.parse(res).tid){
						window.open(
						`${this.url}/welcome?token=${data.data}&user=${res}`,
						"_self"
						);
					}else{
						this.$router.push({ path: "/chooseTeam" });
					}
				});
                // const { data: user } = await ajax.get("/api/user/info");
                // if (user.code === 200) {
                //     setStorage('user', user.data)
                //     this.$notify({
                //         title: "登录成功",
                //         type: "succes",
                //         message: "即将跳转至首页",
                //         duration: 2000,
                //         onClose: () => {
                //             this.$router.push({
                //                 path: "/chooseTeam"
                //             });
                //         }
                //     });
                // }
            }
        },
        // 获取验证码
        async getCode() {
            if (this.timer !== null) {
                return
            }
            const { mobile } = this.accountInfo
            const { data } = await ajax.post("/api/sso/getCode", { mobile } );
            if (data.code === 200) {
                this.handelCountdown()
            }
        },
        handelCountdown() {
            /** *
             * 倒计时
             * */
            this.codeText = `${this.Countdown}s后重新获取`;
            this.timer = setInterval(() => {
                this.Countdown --;
                this.codeText = this.Countdown<10?`0${this.Countdown}s后重新获取`:`${this.Countdown}s后重新获取`;
                if (this.Countdown === 0) {
                    this.codeText = "获取验证码";
                    clearInterval(this.timer);
                    this.Countdown = 60;
                    this.timer = null
                }
            }, 1000);
        },
        checkMobile() {
            this.$router.push('/login')
        },
        forgetPassPw() {
            this.$router.push('/resetPassword')
        },
        // 切换的时候取消校验
        checkLoginStyle(name) {
            const { password, code } = this.form 
            if (name === 'code') {
                this.$refs.form.clearValidate('password')
                if (code.length) {
                    this.$refs.code.focus()
                }
            } else {
                this.$refs.form.clearValidate('code')
                if (password.length) {
                    this.$refs.password.focus()
                }
            }
            this.loginStyle = name
        },
        // 获取二维码
        async getQrCode() {
            const { data } = await ajax.get("/api/qr/code");
            if (data.code === 200) {
                this.qrCodeData = data.data;
                this.pollInterface(this.qrCodeData.uuid);
            }
        },
        // 轮询
        pollInterface(uuid) {
            this.ewmTimer= setInterval(() => {
                this.check(uuid);
            }, 1000);
        },
        // 二维码扫码状态查询
        async check(uuid) {
            const { data } = await ajax.get(`/api/check?uuid=${uuid}`);
            if (data.code === 200) {
                this.checkQrCdoeStatusData = data.data;
                if (data.data.data || data.data.code === 20000) {
                    setStorage("token", data.data.data);
                    clearInterval(this.ewmTimer);
                    const { data: user } = await ajax.get(
                        "/api/user/info"
                    );
                    if (user.code === 200) {
                        setStorage("user", user.data);
                        this.$notify({
                            title: "登录成功",
                            type: "succes",
                            message: "即将跳转至首页",
                            duration: 2000,
                            onClose: () => {
								this.refreshUser().then((res) => {
									if(JSON.parse(res).tid){
										window.open(
										`${this.url}/welcome?token=${data.data.data}&user=${res}`,
										"_self"
										);
									}else{
										this.$router.push({ path: "/chooseTeam" });
									}
                                });
                            }
                        });
                    }
                }
                if (data.data.code === 20002) {
                    this.qrCodeTips = "扫描成功<br />请在手机上点击确定以登录";
                    this.colorCode = "";
                }

                if (data.data.code === 20003) {
                    clearInterval(this.ewmTimer);
                    this.colorCode = "";
                }

                if (data.data.code === 20004) {
                    clearInterval(this.ewmTimer);
                    this.qrCodeTips =
                        "找不到可编辑网站<br />请确认账号是否正确或联系领聚客服";
                    this.getQrCode();
                    this.colorCode = 20004;
                }

                if (data.data.code === 20005) {
                    clearInterval(this.ewmTimer);
                    this.qrCodeTips =
                        "该账号无权<br />请确定账号是否正确或联系团主";
                    this.getQrCode();
                    this.colorCode = 20005;
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>

.login-page {
    width: 100vw;
    height: 100%;
    display: flex;

    .page-left {
        width: 53vw;
        box-sizing: border-box;
        background: url("../../assets/bg.png");
        background-repeat: repeat;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-icon {
            width: 153px;
            height: 53px;
            cursor: pointer;
        }
    }

    .page-right {
        width: 47vw;
        height: 100vh;
        box-sizing: border-box;
        position: relative;
        .down-load-btn {
            position: absolute;
            cursor: pointer;
            top: 24px;
            right: 24px;
            width: 298px;
            height: 44px;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            .down-text {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
            }
            .down-load-img {
                width: 24px;
                height: 24px;
            }
            .code-show {
                position: absolute;
                width: 298px;
                height: 298px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 343px;
                background: #fff;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                &::before {
                    content: "";
                    position: absolute;
                    border-top: 20px solid transparent;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid #fff;
                    width: 0;
                    height: 0;
                    margin-top: -313px;
                    margin-left: -32px;
                }
                img {
                    width: 200px;
                }
            }
        }
        .login-content-box {
            width: 406px;
            padding-bottom: 50px;
            background: #ffffff;
            box-shadow: 0px 4px 20px 4px rgba(74, 130, 244, 0.09);
            border-radius: 20px;
            // border: 1px solid #d8e5fe;
            position: absolute;
            top: 43%;
            left: 50%;
            transform: translate(-50%, -40%);
            position: relative;
            .top-title {
                width: 211px;
                height: 48px;
                background: url("../../assets/top-title-bg.png") no-repeat top
                    center / 100% 100%;
                margin: 0 auto;
                position: absolute;
                margin-top: -25px;
                left: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 25px;
            }

            .login-content {
                .setCodeBox{
                    background-color: #fff;
                    min-width: 405px;
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;
                    border-radius: 15px;
                    .setPassText{
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 30px;
                        margin-top: 20px;
                        color: #999999;
                        p{
                           cursor: pointer;
                        }
                        .checkName{
                            color: #000;
                        }
                    }
                    .ewmPic{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .qr-code-tips{
                            color: rgba(153, 153, 153, 1);
                            text-align: center;
                            font-weight: 400;
                            font-family: "PingFangSC-Regular";
                        }
                        .ewmImg{
                            width: 220px;
                            height: 220px;
                            position: relative;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                            .expiredImg {
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                background: rgba(255, 255, 255, 0.94);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;

                                .text {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #333333;
                                    font-family: "PingFangSC-Regular";
                                }

                                .refresh {
                                    margin-top: 15px;
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    color: rgba(74, 130, 244, 1);
                                    font-size: 16px;
                                    font-weight: 600;
                                    img {
                                        width: 18px;
                                        height: 16px;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                        .ewmText{
                            display: flex;
                            font-size: 14px;
                            margin-top: -34px;
                            z-index: 1;
                            .ljApp{
                                color: #EE2B2B;
                            }
                        }
                    }
                    .getCode{
                        height: 42px !important;
                        display: flex;
                        position: relative;
                        .get-code{
                            color: #4A82F4;
                            cursor: pointer;
                            flex: 1;
                            position: absolute;
                            right: 10px;
                        }
                        .codeDisable{
                            color: #909399;
                        }
                        
                        ::v-deep .el-input {
                            width: 200px;
                        }
                    }
                    .handle-text{
                        // display: flex;
                        // justify-content: space-between;
                        margin-top: 35px;
                        font-size: 14px;
                        .el-checkbox{
                           text-align: left;
                        }
                        p{
                            cursor: pointer;
                            color: #4A82F4;
                            float: right;
                        }
                    }
                    .pass-inp{
                        height: 60px;
                        width: 334px;
                        border-radius: 4px;
                        border: 1px solid #EFEFEF;
                        border-radius: 4px 4px 4px 4px;
                        overflow: hidden;
                        .pass-box{
                            display: flex;
                            img{
                                width: 24px;
                                height: 24px;
                                margin-right: 8px;
                            }
                        }
                        .userInfo{
                            height: 100%;
                            display: flex;
                            align-items: center;
                            img{
                                width: 40px;
                                height: 40px;
                                margin-left: 12px;
                            }
                            .phone{
                                flex: 1;
                            }
                            .checkPhone{
                                cursor: pointer;
                                color: #4A82F4;
                                margin-right: 12px;
                            }
                        }
                        p{
                            font-size: 14px;
                            color: #666666;
                            margin-top: 6px;     
                            height: 20px;
                            text-indent: 1em;
                            line-height: 20px;
                        }
                            ::v-deep .el-input__inner{
                                border: none;
                                height: 32px;
                            }
                            ::v-deep .el-input--suffix{
                                height: 32px;
                            }
                            ::v-deep .el-input__clear {
                                font-size: 20px;
                            }
                    }
                    .loginBtn{
                        display: flex;
                        justify-content: center;
                        .confirmBtn{
                            color: #fff;
                            margin-top: 24px;
                            width: 316px;
                            height: 38px;
                            background: #4A82F4;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 3px;
                            cursor: pointer;
                        }
                    }
                }

                
                .number-content-box {
                    padding: 0 32px;
                    margin-top: 72px;
                    .number-content {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                        .input-content {
                            width: 100%;
                            flex: 1;
                            ::v-deep .el-input-group__prepend{
                                color: #000;
                            }
                        }
                    }
                }
            }
            .login-go {
                margin-top: 23px;
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                .no-under {
                    color: #4a82f4;
                    text-decoration: none;
                }
            }
        }
        .copyright {
            position: absolute;
            width: 100%;
            bottom: 30px;

            .text {
                text-align: center;
            }

            .copy {
                text-align: center;
            }
        }
        .maskGroup{
            position: absolute;
            height: 54px;
            right: 5px;
            bottom: 5px;
            img{
                height: 54px;
                width: 54px;
            }
        }
    }
}
::v-deep .el-form-item__error{
    line-height: 19px;
}
::v-deep .el-form-item {
    margin-bottom: 16px;
}
</style>